import { Container } from "@material-ui/core";
import React from "react";
import { Col, Row } from "react-bootstrap";
import challangeImg from "../../images/Agri-investors_Needs_27-12-2023_-removebg-preview.png";

import Monetize from "../../images/return-of-investment.svg";
import Order from "../../images/financial.svg";

const InvestorChallenges = () => {
    const challanges =[{
        title:"Better returns",
        description:"Agri-investors need better returns to support eco-friendly farming, stay financially strong, and encourage investment in new farming solutions to tackle challenges. Higher returns ensure a prosperous future for agriculture investments.",
        image:Monetize
      },
    
 
      {
        title:"Tangible agri-assets",
        description:"Physical assets, being real and tangible, provide a sense of security and stability compared to more abstract financial instruments.",
        // description:"Agri-investors benefit from tangible assets like farm land and assets, which offer stability, act as valuable collateral, and reduce risks tied to market changes, ensuring a robust and enduring foundation for agricultural investments.",
        image:Order
      },
    //   {
    //     title:"Market Access",
    //     description:"Farmers face the challenge of limited market access, hindering their ability to reach a broader audience and maximize the potential for their agricultural products.",
    //     image:Ecosystem
    //   },
    
      // {
      //   title:"Brand ",
      //   description:"Branding empowers agri-producers to establish unique identities for their products, enabling differentiation and market recognition. ",
      //   image:Brand
      // }
    //  {
    //     title:"Easy Technology adoption ",
    //     description:"Affordable and user-friendly integrated agricultural technologies, enhancing productivity and efficiency on farms, are increasingly sought after by agri-producers.",
    //     image:Technology
    //   }
    ]
    return(
        <Container className="challenge-container subSectionWrapper">
        <h2 className="container-title mb-md-5 text-center">
          Institutional Lenders needs
        </h2>
  
        <Row className="align-items-center">
          <Col md={4}>
            <img src={challangeImg} alt="challange Image" className="mb-md-0 mb-4" />
          </Col>
  
          <Col md={8}>
           
  
            <Row className="challangesRow justify-content-between ">
              {challanges.map((e)=>{
                return <Col md={6} sm={6} xs={10} className="d-flex">
                <div className="challangeCards  mb-md-4 ">
                  <img src={e.image} alt="Challange Icon" className="mb-3" />
                  <h6 className="mb-2">{e.title}</h6>
                  <p className="mb-0">
                   {e.description}
                  </p>
                </div>
              </Col>
             
              })}          
            </Row>
     
          </Col>
        </Row>
      </Container>
    )
};

export default InvestorChallenges
