import React from "react";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import { Container } from "@material-ui/core";
import AgriFoodManufacturersBenefits from "./AgriFoodManufacturersBenefits";
import SolutionEcosystem from "../WebSite/SolutionEcosystem/SolutionEcosystem";
import AgriFoodmanufacturersNeeds from "./AgriFoodmanufacturersNeeds";
import AgriFoodManufacturersJourney from "./AgriFoodManufacturersJourney";
import AgriFoodManufacturerersServices from "./AgriFoodManufacturerersServices";
import BrandPricingData from "../pricingTabs/BrandPricingData";
import CTA from "../CTA/CTA";
import TitleDescription from "../TitleDescription/TitleDescription";
import AgriEntreprenurBenefits from "./AgriEntreprenurBenefits";
import ContractManufactureTypes from "../ContractManufactureTypes/ContractManufactureTypes";
import AgriFoodManufacturerCustomerBenefit from "./AgriFoodManufacturerCustomerBenefit";
import AgriFoodManufacturerContribution from "./AgriFoodManufacturerContribution";
export default function ForAgriFoodManufacturers() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="forManufactures"
      


        bannerText={[
          "We provide system and process to scale an existing ",  <br className="d-lg-block d-none"></br>, "food processing enterprise with zero capital investment",
         
        ]}
        bannerbodycopy="End to End Traceability  | Assured Quality | Supply chain efficiency | Decentralised processing model
          "
          buttonText="Register"
          href="auth/3/signup"
          clicked={() => {
            localStorage.setItem("userType", "FOODPROCESSOR");
          }}
      />

      {/* <h4 class="container-title mb-3">Who are SMEs</h4>
          <p class="container-para px-auto px-md-5">
            Agri-SMEs are small and medium-sized enterprises operating in the
            agricultural sector, encompassing farming, agro-processing, and
            related activities. They play a significant role in food production,
            rural development, and employment generation.
          </p> */}
      <Container>
        <TitleDescription
          classname=""
          title="Who are Food Processing Enterprises"
          description={[
            "Food Processing Enterprises are involved in processing agricultural products into consumable goods, playing a crucial role in the production and transformation of raw materials such as crops and livestock into various food products. Examples include those producing packaged foods, beverages, meat, dairy, and processed agricultural goods, which may be sold under their own brands or for other companies.",
            <br className="d-md-block"></br>,   <br className="d-md-block "></br>,     "Global contract manufacturers | Multinational corporations | Regional manufacturers | Private label producers | Specialty food producers"
          ]}
        />
      </Container>
      {/* <ContractManufactureTypes/> */}
      <div className="main-wrapper2">
        <AgriFoodmanufacturersNeeds />
      </div>
      <SolutionEcosystem
        type="AF"
        bodycopy="CMN network for food processors & partners and PPN to enable decentralized light asset processing model for food processors
        "
      />
      <div className="main-wrapper2">
        <Container>
        <AgriFoodManufacturerersServices />
        </Container>
      </div>
      <div className="MSMEBenfitJourney">
        <Container>
        <AgriFoodManufacturersBenefits />
        </Container>
        <AgriEntreprenurBenefits />
        <Container>
        <AgriFoodManufacturerCustomerBenefit/>
        <AgriFoodManufacturerContribution/>
        </Container>
        <AgriFoodManufacturersJourney /> 
      </div>

      <Container>
        <h2 className="container-title text-center mb-4">
        Food Processing Enterprises Pricing
        </h2>

        <BrandPricingData />
        <div className=" subSectionWrapper">
          <CTA
            href="/auth/3/signup"
            heading="Scale your food processing enterprise with zero capital investment—contact us today to get started!
            "
            btnText="Register Now"
            onClick={()=>{
              localStorage.setItem("userType", "FOOD-PROCESSOR")
            }}
          />
        </div>
      </Container>
      <FooterComponent />
    </>
  );
}
