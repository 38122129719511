import React from 'react';
import HeaderComponent from '../WebSite/Header/HeaderComponent';
import FooterComponent from '../WebSite/Footer/FooterComponent';
import UpdatesBanner from '../UpdatesBanner/UpdatesBanner';
import "./InstitutionalInvestors.css";
import SecondaryNavbar from '../SecondaryNavbar/SecondaryNavbar';
import ClubMainPage from "../Club/ClubMainPage";
import IaaMarketNavbar from '../IaaMarketNavbar/IaaMarketNavbar';
import GBRFarmingClub from '../Club/GBRFarmingClub';
export default function InstitutionalInvestors() {
   
  const userTypes = ["CBO"];

  return (
    
    <>
    <HeaderComponent className="noShadow"/>
    <IaaMarketNavbar/>
    <UpdatesBanner
        className="IAAMarketplaceBanner text-white"
        bannerText="Start exploring CBOs who are looking for investment or Business loan "
        //  buttonText="Sign up"
        //  href="http://localhost:3001/auth/undefined/signup"
      />

        
      {/* <div className="subSectionWrapper">
      <h2 className='container-title mb-md-5 text-center'>Farm Enterprise</h2>

        <ClubMainPage type={["AssetDeveloper"]}  explore = "true"/>
      </div> */}

      <div className="subSectionWrapper pb-0">
        <h2 className='container-title mb-md-5 text-center'>Community Based Organizations</h2>
        <ClubMainPage type={userTypes} registerUser="AssetOEM" explore= "true" />
        {/* <ClubMainPage type={userTypes} registerUser = "AgroDealer" explore = "true"/> */}


        {/* <GBRFarmingClub type={["FOOD-PROCESSOR"]} userTypeRole="Grower"  explore = "true"/> */}
      </div>   
      {/* <div className="subSectionWrapper pb-0">
        <h2 className='container-title mb-md-5 text-center'>Community Based organizations</h2>
        <ClubMainPage type={["CBO"]} userTypeRole="FF"  explore = "true"/>
      </div>
      
      <div className="subSectionWrapper ">
        <h2 className='container-title mb-md-5 text-center'>Agri-food Contract Manufacturers</h2>
        <GBRFarmingClub type={userTypes} userTypeRole="Production-Manager"  explore = "true"/>
      </div>

      <div className="subSectionWrapper ">
        <h2 className='container-title mb-md-5 text-center'>Agri-Entrepreneur</h2>
        <GBRFarmingClub type={["GROWER"]} userTypeRole="FF"  explore = "true"/>
      </div> */}

      {/* <div className="subSectionWrapper ">
        <h2 className='container-title mb-md-5 text-center'>Agri-Transporters</h2>
        <GBRFarmingClub type={userTypes} userTypeRole="Driver"  explore = "true"/>
      </div>

      <div className="subSectionWrapper ">
        <h2 className='container-title mb-md-5 text-center'>Farmer Collectives</h2>
        <GBRFarmingClub type={userTypes} userTypeRole="CBO"  explore = "true"/>
      </div>
     
      <div className="subSectionWrapper ">
        <h2 className='container-title mb-md-5 text-center'>Traders</h2>
        <GBRFarmingClub type={userTypes} userTypeRole="Trader"  explore = "true"/>
      </div> */}
        <FooterComponent/>

    </>
  )
}
