import React, { Component } from "react";
import "./Club.css";
import ReactPaginate from "react-paginate";
// import $, { event, merge } from "jquery";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import { Container, ThemeProvider } from "@material-ui/core";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import index from "reactjs-media";
import { Row, Col } from "react-bootstrap";
import { BsTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import ClubDetails from "./clubDetailsModal/ClubDetails";
import SearchBar from "material-ui-search-bar";
import { NavLink } from "react-router-dom";
import AgentForm from "../AgentForm/AgentForm";
import CBOClubModal from "../CBOClubs/CBOClubModal";

class ClubMainPage extends Component {
  constructor(props) {
    super();
    this.state = {
      InitialAccessToken: localStorage.getItem("GlobalAccess"),
      joinwithus: [],
      filterJoinwithus: [],
      offset: 0,
      clubsPageCount: 1,
      perPage: 6,
      currentPage: 0,
      // userTypes: ["FOOD-PROCESSOR", "AssetDeveloper"],
      userTypes: props.type,
      registerUser:props.registerUser,
      getShow: false,
      showModal: false,
      showClubModal: false,
      showFormModal: false,
      clubname: "",
      userInfoData: [],
      farmgridsearched: "",
      agribusinessName: "",
      roleType: "",
      assetTypes: [],
      edata: [],
      role: {
        roleName: "",
        resources: [],
      },
    };
  }

  componentDidMount() {
    this.getUsers();
  }

  getUsers() {
    for (let i = 0; i < this.state.userTypes.length; i++) {
      this.joinClubValues(this.state.userTypes[i]);
    }
  }

  joinClubValues(userTypes) {
    const url = `${window.appConfig.allConsumerUsres}${window.appConfig.Relam}${window.appConfig.Relam}${userTypes}`;
    console.log("Fetching URL:", url);
  
    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.InitialAccessToken,
      },
    })
      .then((res) => {
        if (res.status !== 200) {
          return null; 
        }
        return res.json();
      })
      .then((res) => {
        if (!res) return; 
  
        if (res.users) {
          this.setState((prevState) => ({
            joinwithus: [...prevState.joinwithus, res.users].flatMap((num) => num),
            filterJoinwithus: [...prevState.filterJoinwithus, res.users].flatMap((num) => num),
            clubsPageCount: Math.ceil(
              [...prevState.joinwithus, res.users].flatMap((num) => num).length /
              this.state.perPage
            ),
          }));
        } else {
          console.log("No users found in response:", res);
        }
      })
      .catch((err) => console.log("Error fetching data:", err));
  }

  /* Clubs Search */
  requestFarmGridSearch(searchedVal) {
    const { filterJoinwithus } = this.state;
    const filterData = filterJoinwithus.filter((data, index) => {
      return data.username.toLowerCase().includes(searchedVal.toLowerCase());
    });
    this.setState({
      joinwithus: filterData,
      clubsPageCount: Math.ceil(filterData.length / this.state.perPage),
    });
  }

  cancelFarmGridSearch = () => {
    this.setState({
      farmgridsearched: "",
    });
    this.requestFarmGridSearch(this.state.farmgridsearched);
  };
  /* EOD  Clubs Search */

  loginHandler(username) {
    localStorage.setItem("clubusername", username);
  }

  /************* Pagination *****************/
  handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offset = selectedPage * this.state.perPage;
    this.setState({
      currentPage: selectedPage,
      offset: offset,
    });
  };
  /************* EOD Pagination *****************/
  /************* View Details modal *****************/
  // modalHandler = () =>{
  //   this.setState({showModal:true});
  //   console.log("jvvvvvvvvvvvvvvv")
  // }

  openModal = (adminName) => {
    this.userInfo(adminName);
  };

  CBOModal = (adminName) => {
    this.CBOAssets(adminName);
  };
  agentFormHandler = (clubname) => {
    this.setState({
      showFormModal: true,
      agribusinessName: clubname,
    });
  };
  userInfo(adminName) {
    fetch(`${window.appConfig.userInfo}${window.appConfig.Relam}${adminName}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.state.InitialAccessToken,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        this.setState({
          userInfoData: res.requestInfo,
          showModal: true,
        });
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  CBOAssets(adminName) {
    fetch(
      `${window.appConfig.getRoleType}${window.appConfig.Relam}${window.appConfig.Relam}${adminName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        this.setState(
          {
            roleType: res.role,
          },
          () => {
            // Call getAllassets inside the callback to ensure roleType is set in the state
            this.getAllassets(this.state.roleType);
          }
        );
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  getAllassets(role) {
    fetch(
      `${window.appConfig.getRoleTypeResource}${window.appConfig.Relam}${window.appConfig.Relam}${role}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.state.InitialAccessToken,
        },
      }
    )
      .then((res) => res.json())
      // .then((res) => {
      //   console.log(res, "API Response");
      //   this.setState({
      //     edata: res
      //   });
      // })
      .then((res) => {
        this.setState({ role: res.role, showClubModal: true });
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }

  closeModal = () => this.setState({ showModal: false });
  closeClubModal = () => this.setState({ showClubModal: false });
  closeFormModal = () => {
    this.setState({ showFormModal: false });
  };
  render() {
    console.log(this.state.edata, "assetTypesassetTypesassetTypes");
    const { resources } = this.state.role;

    const { joinwithus, farmgridsearched, filterJoinwithus } = this.state;
    const clubsList = joinwithus.slice(
      this.state.offset,
      this.state.offset + this.state.perPage
    );

    return (
      <>
        <ClubDetails
          show={this.state.showModal}
          onHide={this.closeModal}
          userInfo={this.state.userInfoData}
        />

        <AgentForm
          show={this.state.showFormModal}
          onHide={this.closeFormModal}
          userInfo={this.state.userInfoData}
          adminName={this.state.agribusinessName}
        />
        <CBOClubModal
          show={this.state.showClubModal}
          onHide={this.closeClubModal}
          resources={resources}
        />
        <Container>
          <div className="club-container ">
            <div id="tendersContainer" className="container-fluid">
              {/* <h3 class="container-title">Clubs</h3> */}
              {/* <div className="mb-3">
              <div className="dropdown">
                <div className="navbarRight club-searchbar navbarRight-mod">
                  <SearchBar
                    placeholder="Search Club Here..."
                    value={farmgridsearched}
                    onChange={(searchedVal) =>
                      this.requestFarmGridSearch(
                        searchedVal
                      )
                    }
                    onCancelSearch={() => this.cancelFarmGridSearch()}
                  />
                </div>
              </div>
            </div> */}

              {clubsList.length > 0 ? (
                <Row className="justify-content-center">
                  {clubsList.map((joindata, index) => {
                    return (
                      <Col md={6} lg={4} sm={12} className="d-flex mb-3">
                        <div className="w-100 d-flex">
                          <div
                            className="card bg-light mb-3"
                            style={{ width: "100%" }}
                          >
                            <div className="text-left card-header container-heading1 mt-0 mb-0 d-flex justify-content-between ">
                              <div className="club-heading">
                                {joindata.username}
                              </div>{" "}
                              <div>
                                {" "}
                                <a
                                  className="text-white me-2"
                                  href={`tel:${joindata.phone}`}
                                >
                                  <BsTelephoneFill />
                                </a>
                                <a
                                  className="text-white"
                                  href={`mailto:${joindata.email}`}
                                >
                                  <MdEmail />
                                </a>
                              </div>
                            </div>
                            <div className="card-body">
                              {this.props.explore !== "club" ? (
                                <h6
                                  className="card-title container-heading2 mb-2"
                                  onClick={() =>
                                    this.openModal(joindata.username)
                                  }
                                >
                                  Click here to view more details
                                </h6>
                              ) : (
                                <h6
                                  className="card-title container-heading2 mb-2"
                                  onClick={() =>
                                    this.CBOModal(joindata.username)
                                  }
                                >
                                  Click here to know about club type
                                </h6>
                              )}

                              {/* {
                            joindata.assettypes.map((assettypes, index)=>{
                              return(
                                <h6 className=" card-content mb-2"><b>Asset Type: </b> {assettypes.assettype}</h6>
                              )
                            })
                          } */}
                             
                                {this.props.agribusiness ? (
                                   <div className="signdiv d-flex justify-content-center">
                                  <button
                                    type="button"
                                    className="btn btn-primary signbutton mt-2"
                                    onClick={() =>
                                      this.agentFormHandler(joindata.username)
                                    }
                                  >
                                    Apply now
                                  </button>
                                  </div>
                                ) : (
                                  <>
                                    {" "}
                                    {this.props.rfq === "true" ? (
                                      <>
                                       <div className="signdiv d-flex justify-content-between">
                                        {" "}
                                        <button
                                          type="button"
                                          className="btn btn-primary signbutton mt-2 d-block"
                                        >
                                          <NavLink
                                            className="text-white text-decoration-none"
                                            to="/auth/3/signup"
                                            onClick={()=>{
                                              localStorage.setItem("userType",this.state.registerUser)
                                                          }}

                                          >
                                            Create RFQ
                                          </NavLink>
                                        </button>{" "}
                                        <button
                                          type="button"
                                          className="btn btn-primary signbutton mt-2 d-block"
                                        >
                                          <NavLink
                                            className="text-white text-decoration-none"
                                            to="/auth/3/signup"
                                          >
                                            View Catalog
                                          </NavLink>
                                        </button>{" "}
                                        </div>
                                      </>
                                    ) : (
                                      <div className="signdiv d-flex justify-content-center">
                                      <button
                                        type="button"
                                        className="btn btn-primary signbutton--mod mt-2"
                                        onClick={() =>
                                          this.loginHandler(joindata.username)
                                        }
                                      >
                                        <NavLink
                                          className="text-white text-decoration-none"
                                          to="/auth/3/signup"

                                          onClick={()=>{
                                            localStorage.setItem("userType",this.state.registerUser)
                                                        }}
                                        >
                                          {this.props.explore === "trueinvest"
                                            ? " Submit proposal"
                                            : this.props.catalog === "true" ? "Submit Services Catalog ":"Connect now"}
                                        </NavLink>
                                      </button>
                                      </div>
                                    )}
                                  </>
                                )}

                                {/* <button
                                type="button"
                                className="btn btn-success signbutton mt-2"
                                onClick={() =>
                                  this.loginHandler(joindata.username)
                                }
                              >
                                <NavLink
                                  className="text-white text-decoration-none"
                                  to="/auth/3/cbnsignin"
                                >
                                  SignIn
                                </NavLink>
                              </button> */}
                          
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                  <div className="paginationComponent">
                    <ReactPaginate
                      previousLabel={"<<"}
                      nextLabel={">>"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={this.state.clubsPageCount}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                    />
                  </div>
                </Row>
              ) : (
                <div className="card">
                  <p className="card-body container-heading2 mb-0">
                    <b> Club's Not Found </b>
                  </p>
                </div>
              )}
            </div>
          </div>
        </Container>
      </>
    );
  }
}

export default ClubMainPage;
