import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import GBRNetworkBusinessCards from "./GBRNetworkBusinessCards";

export default function GBRNetworkBusiness() {
  const businessdata = [
    {
        redirect : "web",
        href:"/",
        classname : "gbr-network-bg",
      purpose: " TRANSACT SECURELY",
      title: "CBON Network",
      subtitle: "We provide system and process to scale an existing community based organizations with zero capital investment",
      // bodycopy:
      //   "Introducing Agribusiness Network (ABN) as a Trusted & Traceable Supply Chain to transform Core Agri-food businesses to Digital Enterprises.        ",
    },
    {
        href:"https://www.gbrfarming.com/",
        classname:"gbr-farming-bg",
        purpose: " ENABLE FARMERS TO SELL DIRECTLY TO MARKET      ",
        title: "GBR Trade",
        subtitle: "Collect, Store and Sell direct to Market to maximize farmers profit!    ",
        bodycopy:
          "Enabling agri-producers to join as a family farmer or GBR Trade club to sell their produce to market to the market for maxium profit ",
      },
      // {
      //   href:"https://www.dtnapp.com/",
      //   classname:"farmUpdateBanner",
      //   purpose: " ACCESS TO CARE        ",
      //   title: "GBR Care",
      //   subtitle: "Nurturing Nature, Caring for All",
      //   bodycopy:
      //     "Enhancing Farms, Naturally! Top-Quality Animal & Plant Care Products for Farmers.          ",
      // },
  ];

  return (
    <div>
      <Container>
      <h2 className='text-center container-title'>Our Businessess</h2>

        <Row className="subSectionWrapper ">
          {businessdata.map((e)=>{
             return (<>
              <Col md={4} classname = "d-flex flex-column">
                <GBRNetworkBusinessCards classname={e.classname} title={e.title} purpose={e.purpose} bodycopy={e.bodycopy} subtitle={e.subtitle} redirect={e.redirect} href={e.href}/>
              </Col>
            </>);
          })}
        </Row>
      </Container>
    </div>
  );
}
