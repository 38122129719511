import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import "./ForAAMC.css";
import TitleDescription from "../TitleDescription/TitleDescription";
import { Container } from "@material-ui/core";
import AAMCServices from "./AAMCServices";
import AAMCBenefits from "./AAMCBenefits";
import AAMCJourney from "./AAMCJourney";
import AAMCContribution from "./AAMCContribution";
import AgriBusinessChallanges from "../WebSite/AgriBusinessChallanges/AgriBusinessChallanges";
import CTA from "../CTA/CTA";
import ABNNET from "../../images/Agri- Business infographics - Desktop - 12-06-2024 C.gif";
import ABNNET1 from "../../images/Agri- Business infographics - Desktop - 12-06-2024 C.gif";
import GrowerBenefits from "./GrowerBenefits"
import BrandPricingData from "../pricingTabs/BrandPricingData";
import FarmEnterpriseType from "../FarmEnterpriseType/FarmEnterpriseType";
import FarmEnterpriseCustomerBenefits from "./FarmEnterpriseCustomerBenefits";
import AssetDeveloperPricing from "../pricingTabs/AssetDeveloperPricing";
export default function ForAAMC() {
  return (
    <>
      <HeaderComponent />
      <UpdatesBanner
        className="AAMCBanner text-white"
        bannerText ="Divesify Revenue stream within current business"
        bannerbodycopy={[
          // "Introduce new line of business to manage assets and ",
          // <br className="d-lg-block d-none"></br>,
          // "Create your own network of Rural-Entrepreneur",

          "We enable farm enterprises to diversify and expand their operations by ",
          <br className="d-lg-block d-none"></br>,
          "introducing a new line of business of managing investable agri assets ",
          <br className="d-lg-block d-none"></br>,
          "through an approved rural agri-entrepreneur network",
        ]}
        demo="true"
        // bannerbodycopy1="New Revenue Stream
        // | Broadening the Scope
        // | Transform Agri-producers into Agri-entrepreneur"
        // bannerbodycopy={[
        //   "New Revenue Stream | Optimised resource management | Boosts agricultural productivity |",
        //   <br className="d-lg-block d-none"></br>,
        //   "Unlocks opportunities for investors and rural entrepreneurs",
        // ]}
        // buttonText2="Sign in"
        // href2="http://abn.gbrfarming.com/"
        // buttonText3="Sign up"
        // href3="http://abn.gbrfarming.com/auth/3/signup
      />
      <Container>
        <TitleDescription
          classname=""
          title="What is Farm Enterprise"
          description={[
            "A farm enterprise, also known as a farming enterprise, is an agribusiness that is primarily engaged in agricultural activities. This includes the cultivation of crops, the raising of livestock, and other related farming practices. We help farm enterprises to become Agri Asset Management Company so that they can assist clients, which can include investors, agri-entrepreneurs , or agricultural businesses, in effectively managing and generating returns from their agricultural assets.",
            // <br className="d-md-block d-none"></br>,
            // "We help farm enterprises to become  Agri Asset Management Company so that they can assist clients, which can include investors, agri-producers, or agricultural businesses, in effectively managing and generating returns from their agricultural assets.",
          ]}
        />
      </Container>

      <div className="main-wrapper2">
        <AgriBusinessChallanges />
      </div>
      <Container
        className="solutionEcosystem-container  subSectionWrapper"
        id="ExploreABN"
      >
        <h2 className="container-title text-center ">Our Solution</h2>
        <p className="container-para text-center mx-0 mx-md-4">
          System and Process to convert farm enterprise into Agri asset management
          company (AAMC).
        </p>

        <img className="mob-vis1 web-img cursor-initial" src={ABNNET} alt="Infographics"/>
        <img className="mob-vis1 mob-img cursor-initial" src={ABNNET1} alt="Infographics"/>

        {/* <img className="mob-vis1 mob-img cursor-initial" src={ABNNET1} alt="Infographics"/> */}
      </Container>

      <Container>
      <AAMCServices />

        <AAMCBenefits />
        <GrowerBenefits/>
        <FarmEnterpriseCustomerBenefits/>
        <AAMCContribution />
        <AAMCJourney />
        <div className="">
          <h2 className="container-title text-center ">Farm Enterprise Pricing</h2>
          <AssetDeveloperPricing />
        </div>
        <div className="subSectionWrapper">
          <CTA
            data="present"
            href="/auth/3/signup"
            heading="Want to manage, build & own rural entrepreneur network"
            btnText="Register Now"
            onClick={()=>{
localStorage.setItem("userType", "AssetDeveloper")
            }}
          />
        </div>
      </Container>

      <FooterComponent />
    </>
  );
}
