import React from 'react'
import AboutList from './AboutList'

export default function Network() {
    const data=[
//   "PPN:  The plant production Network is a sub-network provided by CMN Network to food processing enterprise for effectively managing and coordinating PPN members and plants within their sub-network."  ,
// "DSN:   The Dark store network is a sub-network provided by CMN Network to food processing enterprise for managing Agri-products (processed)  and facilitating their digital transformation journey within the agri-food sector.",

"CBN:   CBN (LTN) App is an Community based application which is easy to manage and maintain the community members and their community services in the organisation.",
     
    ]
  return (
   <AboutList title="Network" contentlist={data}/>
  )
}