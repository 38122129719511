import React from "react";
import { Col, Row } from "react-bootstrap";
import PricingCards from "../PricingCards/PricingCards";

export default function CBOPricing() {
  const BasicFeature = [

    "Billing Service",
   
    // "Process product inventory",
    // "GBR Trace",
    // "Billing",
    // "Access up to 5 stores, 5 store operators & 1 store manager",
    // "onMarketplace",
    // "AAMC with 1 Operator, 5 Growers, 1 Farmer Franchise, 1 Developer QA",
  ];
  const StandardFeature = [
    "Billing Service",
   
    "Own, operate & manage LTN Network",
    "Farmer Facilitation Service",
    "Report"


  ];
  const PreferredFeature = [
    "Billing Service",
   
    "Own, operate & manage LTN Network",
    "Farmer Facilitation Service",
    "Report",
    "onMarketplace"
  ];
  const PremiumFeature = [
    "Billing Service",
   
    "Own, operate & manage LTN Network",
    "Farmer Facilitation Service",
    "Report",
    "onMarketplace",
    "Business Diversification Service"
  ];



  const data = [

    {
      title: "Basic",
      annually: "5000",
      saving:"988",
      btnText: "Sign up ",
      price: "499",
      features: BasicFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "CBO");
      },
    },
    {
      title: "Standard",
    
      annually: "10000",
      saving:"1988",
      btnText: "Sign up ",
      price: "999",
      features: StandardFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "CBO");
      },
    },

    {
      title: "Preferred",
    
      annually: "50000",
      saving:"9988",
      btnText: "Sign up ",
      price: "4999",
      features: PreferredFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "CBO");
      },
    },

    {
      title: "Premium",
    
      annually: "100000",
      saving:"19988",
      btnText: "Sign up ",
      price: "9999",
      features: PremiumFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "CBO");
      },
    },

    // {
    //     title: "For Production Manager",
    //     annually: "11988",
    //     btnText: "Sign up ",
    //     price: "999",
    //     features: StandardFeature,
    //     href:"/auth/3/signup",
    //     onClick: function() {
    //       localStorage.setItem("userType", "FF");
    //     }
    //   },
  ];

  return (
    <>
      <Row className="justify-content-center">
        {data.map((e) =>{
         return   <Col lg={4} md={6} sm={12} className="d-flex  mb-4">
                <PricingCards onClick={e.onClick} href={e.href} newTab={e.newTab} title={e.title} annually={e.annually} price={e.price} btnText={e.btnText} features={e.features} customText={e.customText} saving={e.saving}/>
            </Col>
        })}
      </Row>
    </>
  );
}
