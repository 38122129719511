import React from "react";
import HeaderComponent from "../WebSite/Header/HeaderComponent";
import FooterComponent from "../WebSite/Footer/FooterComponent";
import SecondaryNavbar from "../SecondaryNavbar/SecondaryNavbar";
import UpdatesBanner from "../UpdatesBanner/UpdatesBanner";
import HomeSubSection from "../WebSite/HomeSubSection/HomeSubSection";
// import "./OnMarketplaceSell.css";
// import MarketPlaceQuote from "../MarketPlaceQuote/MarketPlaceQuote";
// import MarketPlaceQuotePP from "../MarketPlaceQuote/MarketPlaceQuotePP";

import { useLocation, useHistory } from "react-router-dom";

export default function BuyMarketPlaceSell(){
  
  let navData = [
    { to: "/buy-asset-marketplace", link: "For Buyers" },
    { to: "/buy-asset-marketplace-sell", link: "For Sellers" }
    // { to: "/onMarketplaceRFQ", link: "RFQ" }
  ];

    return(
        <>
        <HeaderComponent className="noShadow"/>
        <SecondaryNavbar props={navData}/>
        <UpdatesBanner 
         className="buy-asset-marketplace-bg text-white"
         bannerText="Start selling Agri-food assets with GBR"
        
        />

<div className="subSectionWrapper">


<HomeSubSection
className="rowReverseAbout--mod borderedCards--mod borderedCards"
className3="image29"
description="Within the dynamic GBR community, we partner with leading processing machinery providers to offer essential solutions customized for farms. This collaborative effort ensures agri-producers gain access to expert guidance and high-quality processing machinery options, resulting in improved efficiency, productivity, and sustainability for their farming operations."
descriptionTitle="
Processing Machinery"
href2="auth/3/signup"
buttoncont2="Register"
onClick={() => {
    localStorage.setItem("userType", "PMC");
  }}
/>

<HomeSubSection
className="rowReverseAbout borderedCards--mod borderedCards"
className3="image28"
description="Within the vibrant GBR community, we join forces with renowned agri machinery providers to deliver essential solutions tailored for farms. This cooperative effort ensures agri-producers benefit from expert guidance and superior agri machinery options, ultimately enhancing the efficiency, productivity, and sustainability of their farming operations."
descriptionTitle="
Farming Machinery"
href2="auth/3/signup"
buttoncont2="Register"
onClick={() => {
    localStorage.setItem("userType", "FMC");
  }}

  
/>


<HomeSubSection
  className="rowReverseAbout--mod borderedCards--mod borderedCards"
  className3="agri-asset"
  description="Within the vibrant GBR community, we join forces with renowned agri machinery providers to deliver essential solutions tailored for farms. This cooperative effort ensures agri-producers benefit from expert guidance and superior agri machinery options, ultimately enhancing the efficiency, productivity, and sustainability of their farming operations."
  descriptionTitle="
Agri Assets"
href2="auth/3/signup"
buttoncont2="Register"
onClick={() => {
    localStorage.setItem("userType", "AssetDeveloper");
  }}
/>

</div>
        <FooterComponent/>
        </>
    )
}