import React from "react";
import Toast from "light-toast";
import { Container } from "@material-ui/core";
import "react-awesome-slider/dist/styles.css";
import HeaderComponent from "../../WebSite/Header/HeaderComponent";
import FooterComponent from "../../WebSite/Footer/FooterComponent";
import "../App.css";
import SolutionEcosystem from "../SolutionEcosystem/SolutionEcosystem";

import UpdatesBanner from "../../UpdatesBanner/UpdatesBanner";
import CTA from "../../CTA/CTA";

import ForFarmerBenefits from "../ForFarmerBenefits/ForFarmerBenefits";
import ForFarmerChallenges from "../ForFarmerChallenges/ForFarmerChallenges";
import ForInputProviderChallenges from "../ForInputProviderChallenges/ForInputProviderChallenges";
import InputProviderBenefits from "../InputProviderBenefits/InputProviderBenefits";
import GBRFarmerService from "../../AgriProducers/Farmer/GBRFarmerService";
export default function ForInputProviders() {
  return (
    <>
    <HeaderComponent />
    <UpdatesBanner
    
      className="farmUpdateBanner"
      bannerTitle ="Input Manufacturers"
      bannerbodycopy1={[
"We help Input Manufacturers to expand their markets"          ]}
      // bannerbodycopy="Brand Recognization | Increased Income | Job Creations | Market Access | Value Addition"
      // buttonText2="Sign in"
      // href2="http://abn.gbrfarming.com/"
      buttonText="Sign up"
      href="auth/3/signup"
      clicked={() => {
        localStorage.setItem("userType", "PMC");
      }} 
    />
    <div className="main-wrapper2">
      <ForInputProviderChallenges/>
    </div>
   
    <SolutionEcosystem type = "IP" bodycopy = "Provides ecosystem for Input Manufacturers, Retailers, investors,  Farmers and Farmer Collectives"/>
    <div className="main-wrapper2">

    <InputProviderBenefits/>
    </div>

    <GBRFarmerService/>


    <Container>
      {" "}
      {/* <AgriEntrepenurBenefits /> */}
      <div className="subSectionWrapper">
      <CTA
      // secondBtn= "true" 
      // href3="/agri-business"
      // btnText2= "Explore Agri Business"
      data="present"
                onClick={()=>{
                  localStorage.setItem("userType", "FMC")
                              }}
                  href="auth/3/signup"
                heading="Optimize Your Sales Today with CBON Network's Advanced Solutions!"
                btnText="Register now for free"
      />
    </div>
    </Container>
   
    <FooterComponent />
  </>
  )
}
