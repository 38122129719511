import React from "react";
import { Col, Row } from "react-bootstrap";
import PricingCards from "../PricingCards/PricingCards";


export default function AssetDeveloperPricing() {
  const BasicFeature = [

    "Billing Service",
    "GBR Trace",
    "onMarketplace",
    "Buy Asset Marketplace"
    // "Process product inventory",
    // "GBR Trace",
    // "Billing",
    // "Access up to 5 stores, 5 store operators & 1 store manager",
    // "onMarketplace",
    // "AAMC with 1 Operator, 5 Growers, 1 Farmer Franchise, 1 Developer QA",
  ];
  const StandardFeature = [
    "Billing Service",
    "GBR Trace",
    "onMarketplace",
    "Report",
    "Own, operate & manage AAMC Network",
    "AE onboarding service",
    "Buy Asset Marketplace"



  ];
  const PreferredFeature = [
    "Billing Service",
    "GBR Trace",
    "onMarketplace",
    "Report",
    "Own, operate & manage AAMC Network",
    "AE onboarding service",
    "Social onMarketplace",
    "Social iaaMarketplace",
    "Buy Asset Marketplace"

  ];
  const PremiumFeature = [
    "Billing Service",
    "GBR Trace",
    "onMarketplace",
    "Report",
    "Own, operate & manage AAMC Network",
    "AE onboarding service",
    "Social onMarketplace",
    "Social iaaMarketplace",
    "Business Diversification Service",
    "Buy Asset Marketplace"

  ];
  // const CustomizedFeature = [
  //   "Customize the number of assets to create and manage",
  //   "Customize the number of sites to create and manage",
  //   "Customize the number of Grower, Farmer Franchise, Production-manager, QA and Operator ",
  //   "GBR Asset Trace",
  //   "Access to Global Network to create brand",
  //   "Receive Contract manufacturing from other brands",
  //   "Request for AssetDeveloper to create & manage own farmer network"
  // ];

  const data = [
    {
      title: "Basic",
      saving:"3988",
      annually: "20000",
      btnText: "Sign up ",
      price: "1999",
      features: BasicFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "AssetDeveloper");
      },
    },
    {
      title: "Standard",
 saving:"5988",
      annually: "54000",
      btnText: "Sign up ",
      price: "4999",
      features: StandardFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "AssetDeveloper");
      },
    },

    {
      title: "Preferred",
    
      annually: "110000",
      saving:"9988",
      btnText: "Sign up ",
      price: "9999",
      features: PreferredFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "AssetDeveloper");
      },
    },

    {
      title: "Premium",
    
      annually: "200000",
      saving:"39988",
      btnText: "Sign up ",
      price: "19999",
      features: PremiumFeature,
      href:"/auth/3/signup",
      onClick: function() {
        localStorage.setItem("userType", "AssetDeveloper");
      },
    },
  ];

  return (
    <>
      <Row className="justify-content-center">
        {data.map((e) => {
          return (
            <Col lg={4} md={6} sm={12} className="d-flex  mb-4">
                              <PricingCards onClick={e.onClick} href={e.href} newTab={e.newTab} title={e.title} annually={e.annually} price={e.price} btnText={e.btnText} features={e.features} customText={e.customText} saving={e.saving}/>

            </Col>
          );
        })}
      </Row>
    </>
  );
}
